.form {
  margin-bottom: 2rem;
}

.input {
  padding: 5px;
  margin-right: 5px;
  border: var(--color-primary) solid 2px;
  border-radius: 5px;
}

.button {
  font-weight: bold;
  cursor: pointer;
  padding: 5px;
  background: none;
  border: var(--color-primary) solid 2px;
  color: var(--color-primary-darker);
  border-radius: 5px;
}

.button:hover {
  background: rgba(255, 255, 255, 0.5);
}
