@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&family=Rubik:wght@700&display=swap");

* {
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --color-primary: rgb(0, 175, 250);
  --color-primary-darker: rgb(0, 100, 150);
}

html {
  background: #00356b;
}

h1 {
  font-family: "Rubik", sans-serif;
}
