.listItem {
  padding: 5px 10px;
  margin: 5px 0;
  border: var(--color-primary) solid 2px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: space-between;
  gap: 5rem;
}

.itemTitle {
  padding-left: 5px;
  font-weight: 500;
}

.itemActions {
  padding-top: 3px;
}

.itemActions button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 10px;
  color: var(--color-primary-darker);
}

@media only screen and (max-width: 600px) {
  .listItem {
    padding: 2px 5px;
    max-width: 95vw;
    grid-gap: 0;
    gap: 0;
  }
  .itemActions button {
    padding: 0 5px;
  }
}
