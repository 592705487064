.outerContainer {
  background-image: url("../background/bg.jpeg");
  background-size: cover;
  min-height: 100vh;
  overflow: hidden;
}

.innerContainer {
  margin: 5rem 10rem;
  color: #fff;
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.cardContent {
  display: flex;
  gap: 1rem;
  padding: 3rem 4rem;
  text-align: end;
}

.cardBody {
  flex: 3;
}

.cardBody h3 {
  margin: 0;
}

.cardImg {
  flex: 1;
}

.img {
  width: 100%;
  border-radius: 100%;
}

@media (max-width: 768px) {
  .innerContainer {
    margin: 5rem;
  }
  .img {
    width: 0;
  }
  .cardContent {
    padding: 0;
  }
}
