.navBar {
  padding: 15px 0;
  text-align: end;
  position: fixed;
  top: 0;
  width: 100%;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.35);
}

.navLink {
  text-decoration: none;
  padding: 5px;
  margin-left: 15px;
  transition: all 0.5s;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
}

.navLink:last-of-type {
  margin-right: 1rem;
}

.light .navLink {
  color: white;
}

.light .navLink:hover {
  border-color: white;
}

.dark .navLink {
  color: var(--color-primary-darker);
}

.dark .navLink:hover {
  border-color: var(--color-primary-darker);
}

@media only screen and (max-width: 600px) {
  .navBar {
    text-align: center;
  }
}
